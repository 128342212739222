<template>
  <div class="d-flex flex-column">
    <transition name="page">
      <template v-if="loading.length === 0">
        <div v-if="insurances.length > 0" class="row no-gutters">
          <div v-for="(item, i) in insurances" :key="i" class="col-12 col-md-6">
            <insurance-item
              :title="item.title"
              :link="{ name: 'insurance-id', params: { ...$route.params, id: item.id } }"
              :imageUrl="businessTypes[item.businessType]
                ? businessTypes[item.businessType].url : '#'"
              :color="businessTypes[item.businessType]
                ? businessTypes[item.businessType].color : '#fff'"
              :businessType="item.businessType"
              :type="item.type"
              :description="item.description"
              :startDate="item.startDate"
              :endDate="item.endDate"
              :location="item.city"
              :showPayout="['active', 'finished', 'claimed'].includes(item.statusType)"
              :price="item.price.toString()"
              :payout="item.payout.toString()"
              :status="item.status"
              :currency="item.currency"
              :potential_payout="str.insurance.potential_payout"
              :claim_payout_btn="str.insurance.claim_payout_btn"
              :price_including_vat="str.commonApp.price_including_vat"
              :weather_type="str.commonApp.weather_type ?
              str.commonApp.weather_type[item.type] : ''"
            ></insurance-item>
          </div>
        </div>
        <div
          v-if="loading.length === 0 && insurances.length === 0"
          class="flex-grow-1 d-flex align-items-center justify-content-center"
        >
          <div class="row w-100">
            <div class="col-12 col-sm-6 mx-auto text-center">
              <div class="wet-no-contract-icon mb-4 mx-auto">
                <weather-icon
                  class="wet-icon-2xl text-secondary"
                  type="rain"
                  intensity="high"
                ></weather-icon>
              </div>
              <div class="mb-2 font-weight-bold">
                {{ str.insurance.no_contract_title }}
              </div>
              <div class="is-size-7 mb-4">
                {{ str.insurance.no_contract_subtitle }}
              </div>
              <button @click.prevent="newContract" class="wet-button">
                {{ str.insurance.no_contract_button }}
              </button>
            </div>
          </div>
        </div>
      </template>
    </transition>
  </div>
</template>
<script>
import common from '@/mixins/common';
import insuranceHelper from '@/mixins/insurance';
import { mapState } from 'vuex';
import changeLang from '@/mixins/changeLang';
import insuranceItem from '@/components/insurance-item.vue';
import weatherIcon from '@/components/weatherIcon.vue';
import wpSection from '@/enums/wpSection';
import LogService from '@/services/LogService';
import { commonApp, insurance } from '@/enums/stringIndices';

export default {
  mixins: [common, insuranceHelper, changeLang],
  components: {
    insuranceItem,
    weatherIcon,
  },
  data() {
    return {
      apiOrderList: '/api/user/order/list',
      apiData: [],
      str: {
        commonApp,
        insurance,
      },
    };
  },
  computed: {
    ...mapState(['lang', 'loading']),
    insurances() {
      const apiData = [...this.apiData];
      // sort by status & start date
      apiData.sort((a, b) => {
        // sort by status
        let res = this.getStatus(a).order - this.getStatus(b).order;
        if (res === 0) {
          // sort by startdate
          const getStartDate = (dates) => this.$moment.min(dates.map((date) => this.$moment(date)));
          res = getStartDate(b.dates).isAfter(getStartDate(a.dates)) ? 1 : -1;
        }
        return res;
      });
      return apiData.map((i) => this.mapApiData(i));
    },
  },
  async created() {
    await this.init();
  },
  destroyed() {
    // unwatch next & back buttons click watcher
    this.$root.$off('buttonNext', this.next);
    this.$root.$off('buttonBack', this.back);
  },
  methods: {
    async getOrders() {
      await this.callApi(this.apiOrderList)
        .then((res) => {
          if (res.data?.user?.status
            && ![0, 1].includes(Number.parseInt(res.data?.user?.status, 10))) {
            // redirect to login
            this.$router.replace({ name: 'login', params: { ...this.$route.params }, query: { redirect: 'insurance' } });
          }
          LogService.log('res.data', res?.data);
          this.apiData = res?.data?.orders || [];
          this.$root.$on('buttonNext', this.next);
          this.$root.$on('buttonBack', this.back);
        })
        .catch(() => {
          // redirect to login
          this.$router.replace({ name: 'login', params: { ...this.$route.params }, query: { redirect: 'insurance' } });
        });
    },
    async init() {
      await Promise.all([
        this.getWordPressStrings(
          [
            wpSection.INSURANCE,
          ],
        ),
        this.getOrders(),
      ]);
      await LogService.log('getOrders finished');
    },
    newContract() {
      // this.$router.push({ name: 'home', params: { ...this.$route.params } });
      window.location = `/home/${this.$route.params?.lang || ''}`;
    },
    next() {
      console.log('next');
    },
    back() {
      // this.$router.push({ name: 'home', params: { ...this.$route.params } });
      window.location = `/home/${this.$route.params?.lang || ''}`;
    },
  },
};
</script>
<style lang="scss">
  .wet-no-contract-icon {
    border: 2px solid var(--secondary);
    width: 5rem;
    height: 5rem;
    border-radius: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
